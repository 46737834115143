<template>
  <div class="w-full h-screen flex items-center justify-center -my-24">
    <div class="text-center">
      <v-badge>404</v-badge>
      <div class="m-2 leading-5 text-gray-600">
        This page could not be found.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
